const baseApiURL = process.env.REACT_APP_API_BASE_URL;
const baseURL = process.env.REACT_APP_BASE_URL;
const logoutRedirect = process.env.REACT_APP_LOGOUT_URL;

export const ApiPath = {
  login: `${baseURL}backend/login`,
  upload: `${baseApiURL}upload`,
  read: `${baseApiURL}read`,
  query: `${baseApiURL}query`,
  userQuery: `${baseApiURL}user-query`,
  delete: `${baseApiURL}delete`,
  userInfo: `${baseApiURL}who-is-who`,
  logout: `${baseApiURL}logout`,
  logoutRedirect: `${logoutRedirect}`,
};

export default ApiPath;
