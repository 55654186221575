import react from "react";
import { BrowserRouter as Router, Navigate, Routes, Route } from "react-router-dom";
import loadable from "@loadable/component";
import { useUser, ProtectedRoute } from "./utils";
import { LoginPage } from "./pages";
import { allRoles, queryRoles, uploadRoles } from "./constants";
import RedirectionAlert from "./pages/RedirectionAlert";

const UploadPage = loadable(() => import("./pages/UploadPage"));
const QueryPage = loadable(() => import("./pages/QueryPage"));

const App = () => {
  const user = useUser();
  const redirectPath =
    user.payload === "STATS.SuperAdmin" || user.payload === "STATS.Viewer"
      ? "/query"
      : "/upload";

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to={redirectPath} />} />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/upload"
          element={
            user.isActive !== true ? (
              <Navigate to="/login" />
            ) : uploadRoles.includes(user.payload) ? (
              <UploadPage user={user} validRoles={uploadRoles} />
            ) : allRoles.includes(user.payload) ? (
              <RedirectionAlert message="Sorry! You do not have access to this functionality." redirectedPath="/" />
            ) : (
              <RedirectionAlert message="Sorry! You do not have access to the application." redirectedPath="/login" />
            )
          }
        />
        <Route
          path="/query"
          element={
            user.isActive !== true ? (
              <Navigate to="/login" />
            ) : queryRoles.includes(user.payload) ? (
              <QueryPage user={user} validRoles={queryRoles} />
            ) : allRoles.includes(user.payload) ? (
              <RedirectionAlert message="Sorry! You do not have access to this functionality." redirectedPath="/" />
            ) : (
              <RedirectionAlert message="Sorry! You do not have access to the application." redirectedPath="/login" />
            )
          }
        />
      </Routes>

      {/* <ProtectedRoute
        exact
        path="/query"
        element={<QueryPage />}
        user={user}
        validRoles={queryRoles}
      /> */}
    </Router>
  );
};

export default App;
