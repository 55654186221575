import React from "react";
import {
  PARSE_INIT,
  PARSE_SUCCESS,
  PARSE_FAILURE,
  BROWSER_SUPPORT_WARNING,
  FILE_TYPE_WARNING,
  ERROR,
} from "../constants";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";
import {
  Flex,
  useColorModeValue,
  Progress,
  Tag,
  TagLabel,
  TagCloseButton,
  HStack,
  VStack,
  Text,
  Icon,
  chakra,
  useToast,
} from "@chakra-ui/react";
import { RiFileExcel2Fill } from "react-icons/ri";

const BrowseButton = ({ handleBrowse }) => {
  const handleChange = (event) => {
    const files = event.target.files;
    if (files && files[0]) handleBrowse(files[0]);
  };

  return (
    <chakra.form sx={{ display: "inline" }}>
      <chakra.label htmlFor="excel-file">
        <Text
          as="lable"
          color={useColorModeValue("brand.500", "brand.200")}
          fontSize="lg"
          decoration="underline"
          sx={{ cursor: "pointer" }}
        >
          browse
        </Text>
      </chakra.label>
      <input
        type="file"
        id="excel-file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        onChange={handleChange}
        hidden
      />
    </chakra.form>
  );
};

const UploadArea = ({ parsedData, isParsing, isUploading, dispatch }) => {
  const toast = useToast({
    duration: 4000,
    isClosable: true,
    position: "bottom-right",
    variant: "solid",
  });

  const handleFile = (file) => {
    dispatch({ type: PARSE_INIT });
    try {
      const regex = /^.*\.(xls|xlsx)$/;
      const filename = file.name;
      if (regex.test(filename.toLowerCase())) {
        if (typeof FileReader !== "undefined") {
          const reader = new FileReader();
          if (reader.readAsBinaryString) {
            reader.onload = (event) => {
              parseExcel(reader.result, filename);
            };
            reader.readAsBinaryString(file);
          }
        } else {
          toast({ title: BROWSER_SUPPORT_WARNING, status: "warning" });
          dispatch({ type: PARSE_SUCCESS, payload: {} });
        }
      } else {
        toast({ title: FILE_TYPE_WARNING, status: "warning" });
        dispatch({ type: PARSE_SUCCESS, payload: {} });
      }
    } catch (err) {
      console.error(err);
      dispatch({ type: PARSE_FAILURE, payload: {} });
      toast({ title: ERROR, status: "error" });
    }
  };

  const parseExcel = (data, filename) => {
    try {
      const workbook = XLSX.read(data, {
        type: "binary",
        cellDates: true,
        cellText: false,
      });
      const firstSheet = workbook.SheetNames[0];
      const excelRows = XLSX.utils.sheet_to_row_object_array(
        workbook.Sheets[firstSheet],
        {
          raw: false,
          dateNF: 'YYYY"-"MM"-"DD HH":"mm":"ss',
        }
      );
      const formattedExcelRows = excelRows.map((row) =>
        Object.fromEntries(
          Object.entries(row).map(([k, v]) => [k.toLowerCase(), v])
        )
      );
      dispatch({
        type: PARSE_SUCCESS,
        payload: { data: formattedExcelRows, filename },
      });
    } catch (err) {
      console.error(err);
      dispatch({ type: PARSE_FAILURE, payload: {} });
      toast({ title: ERROR, status: "error" });
    }
  };

  const DragAndDropContent = () => {
    if (isParsing || isUploading) {
      return (
        <Progress
          size="sm"
          hasStripe
          isAnimated
          isIndeterminate
          colorScheme="brand"
          w="full"
        />
      );
    } else if (Array.isArray(parsedData.data) && parsedData.data.length) {
      return (
        <HStack spacing={4} maxWidth="100%">
          <Tag
            size="md"
            key="lg"
            borderRadius="full"
            variant="solid"
            colorScheme="brand"
          >
            <TagLabel isTruncated>
              <Text isTruncated>{parsedData.filename}</Text>
            </TagLabel>
            <TagCloseButton
              onClick={() =>
                dispatch({
                  type: PARSE_SUCCESS,
                  payload: { data: null, filename: "" },
                })
              }
            />
          </Tag>
        </HStack>
      );
    } else {
      return (
        <VStack spacing={1} align="center">
          <Text fontSize="md" align="center">
            Drag and drop an Excel file
          </Text>
          <Text fontSize="md" align="center">
            or <BrowseButton handleBrowse={handleFile} /> on your computer
          </Text>
        </VStack>
      );
    }
  };

  const DragAndDropArea = ({ children, handleExcel }) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      noClick: true,
      noKeyboard: true,
      onDrop: (acceptedFiles) => {
        if (acceptedFiles && acceptedFiles[0]) handleExcel(acceptedFiles[0]);
      },
      accept:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
      maxFiles: 1,
    });

    return (
      <Flex
        align="center"
        justify="center"
        borderRadius="5"
        borderWidth="2px"
        borderColor={useColorModeValue(
          isDragActive ? "brand.500" : "gray.300",
          isDragActive ? "brand.200" : "gray.600"
        )}
        p={4}
        minHeight="150px"
        w="full"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <VStack spacing={3} align="center" w="100%">
          <Icon
            as={RiFileExcel2Fill}
            boxSize={8}
            color={useColorModeValue("brand.500", "brand.200")}
          />
          {children}
        </VStack>
      </Flex>
    );
  };

  return (
    <DragAndDropArea handleExcel={handleFile}>
      <DragAndDropContent />
    </DragAndDropArea>
  );
};

export default UploadArea;
