import React from "react";
import {
  useColorModeValue,
  IconButton,
  HStack,
  Flex,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Heading,
} from "@chakra-ui/react";
import { AiOutlineMenu } from "react-icons/ai";
const SidebarLayout = ({ sidebarContent, mainContent, mobileSideNav }) => {
  const MobileSideNavigation = () => (
    <Drawer
      isOpen={mobileSideNav.isOpen}
      size="xs"
      placement="left"
      onClose={mobileSideNav.onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Heading as="h1" fontSize="lg">
            AS Statistiks 2.0
          </Heading>
        </DrawerHeader>
        <DrawerBody>{sidebarContent}</DrawerBody>
      </DrawerContent>
    </Drawer>
  );

  return (
    <HStack as="section" h="full" align="flex-end" justify="flex-end" ml={0}>
      <Flex
        align="flex-start"
        h="full"
        bg={useColorModeValue("white", "gray.800")}
        borderRightWidth="1px"
        borderRightColor={useColorModeValue("gray.300", "whiteAlpha.300")}
        display={{ base: "none", md: "flex" }}
        minW={160}
      >
        {sidebarContent}
      </Flex>
      <Flex
        align="flex-start"
        h="full"
        ml={2}
        bg={useColorModeValue("white", "gray.800")}
        borderRightWidth="1px"
        borderRightColor={useColorModeValue("gray.300", "whiteAlpha.300")}
        display={{ base: "flex", md: "none" }}
      >
        <IconButton
          ml={-4}
          aria-label="Open menu"
          fontSize="20px"
          color={useColorModeValue("gray.800", "inherit")}
          variant="ghost"
          mr={2}
          mt={4}
          icon={<AiOutlineMenu />}
          onClick={mobileSideNav.onOpen}
        />
        <MobileSideNavigation />
      </Flex>
      <Flex
        grow={1}
        py={4}
        pl={4}
        minH="full"
        w="100%"
        maxW="100%"
        overflow="hidden"
        bg={useColorModeValue("white", "gray.800")}
      >
        {mainContent}
      </Flex>
    </HStack>
  );
};
export default SidebarLayout;
