import React from "react";
import { Center, Spinner } from "@chakra-ui/react";

const FullPageSpinner = () => {
  return (
    <Center bg="white" h="100vh">
      <Spinner color="brand.500" size="xl" />
    </Center>
  );
};

export default FullPageSpinner;
