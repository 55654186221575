import React from "react";
import {
  Tr,
  Th,
  Td,
  Tab,
  Text,
  Tabs,
  Thead,
  Table,
  Tbody,
  Center,
  Button,
  Spinner,
  TabList,
  TabPanel,
  TabPanels,
  useColorModeValue,
} from "@chakra-ui/react";
import { CustomTag } from "../components";

const TableContent = ({
  table,
  isLoading,
  emptyInfo,
  savedQueries,
  selectedTable,
}) => {
  if (isLoading) {
    return (
      <Center h="full">
        <Spinner color="brand.500" />
      </Center>
    );
  } else if (
    savedQueries.filter((item) => item.table === selectedTable).length
  ) {
    return table;
  } else {
    return (
      <Center h="full">
        <Text>{emptyInfo}</Text>
      </Center>
    );
  }
};

const SavedQueriesTable = ({
  isLoading,
  savedQueries,
  selectedTable,
  runSavedQuery,
  editSavedQuery,
  deleteSavedQuery,
}) => {
  const tableBorder = useColorModeValue("gray.200", "whiteAlpha.300");
  const tableBackground = useColorModeValue("gray.50", "gray.700");

  return (
    <Tabs isLazy colorScheme="brand" variant="enclosed-colored">
      <TabList
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Tab isDisabled>Saved Queries</Tab>
        <CustomTag text={selectedTable} ml={2} />
      </TabList>
      <TabPanels>
        <TabPanel
          p={0}
          h="55vh"
          maxH="55vh"
          border="1px"
          overflowY="auto"
          borderColor={tableBorder}
        >
          <TableContent
            isLoading={isLoading}
            table={
              <Table variant="unstyled" size="sm">
                <Thead pos="sticky" top="0" bg={tableBackground} zIndex={10}>
                  <Tr borderBottom="1px" borderColor={tableBorder}>
                    <Th pl={4} py={4}>
                      Query Name
                    </Th>
                    <Th />
                    <Th />
                    <Th />
                  </Tr>
                </Thead>
                <Tbody>
                  {savedQueries
                    .filter((item) => item.table === selectedTable)
                    .map((query) => (
                      <Tr
                        borderBottomWidth="1px"
                        borderColor={tableBorder}
                        key={query.queryName}
                      >
                        <Td
                          pl={4}
                          w="100%"
                          maxW="650"
                          borderRightWidth="1px"
                          borderColor={tableBorder}
                        >
                          <Text isTruncated>{query.name}</Text>
                        </Td>
                        <Td borderRightWidth="1px" borderColor={tableBorder}>
                          <Center>
                            <Button
                              colorScheme="brand"
                              variant="ghost"
                              size="sm"
                              fontSize="xs"
                              onClick={() => runSavedQuery(query)}
                              isLoading={query.isExecuting}
                            >
                              RUN
                            </Button>
                          </Center>
                        </Td>
                        <Td borderRightWidth="1px" borderColor={tableBorder}>
                          <Center>
                            <Button
                              colorScheme="gray"
                              variant="ghost"
                              size="sm"
                              fontSize="xs"
                              onClick={() => editSavedQuery(query)}
                            >
                              EDIT
                            </Button>
                          </Center>
                        </Td>
                        <Td>
                          <Center>
                            <Button
                              colorScheme="red"
                              variant="ghost"
                              size="sm"
                              fontSize="xs"
                              onClick={() => deleteSavedQuery(query)}
                              isLoading={query.isDeleting}
                            >
                              DELETE
                            </Button>
                          </Center>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            }
            emptyInfo={
              <>
                No queries have been saved for{" "}
                <CustomTag text={selectedTable} /> table
              </>
            }
            savedQueries={savedQueries}
            selectedTable={selectedTable}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default SavedQueriesTable;
