export const FETCH_INIT = "FETCH_INIT";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_FAILURE = "FETCH_FAILURE";
export const PARSE_INIT = "PARSE_INIT";
export const PARSE_SUCCESS = "PARSE_SUCCESS";
export const PARSE_FAILURE = "PARSE_FAILURE";
export const UPLOAD_INIT = "UPLOAD_INIT";
export const UPLOAD_FINISH = "UPLOAD_FINISH";
export const SET_XML = "SET_XML";
export const DELETE_INIT = "DELETE_INIT";
export const DELETE_TABLE = "DELETE_TABLE";
export const DELETE_FAILURE = "DELETE_FAILURE";
export const SET_TABLE = "SET_TABLE";
export const SET_QUERY = "SET_QUERY";
export const SET_TAB = "SET_TAB";
export const SET_SQL_QUERY = "SET_SQL_QUERY";
export const QUERY_INIT = "QUERY_INIT";
export const SET_EXPORT_DATA = "SET_EXPORT_DATA";

export const SET_SAVED_QUERIES = "SET_SAVED_QUERIES";
export const ADD_SAVED_QUERY = "ADD_SAVED_QUERY";
export const UPDATE_SAVED_QUERY = "UPDATE_SAVED_QUERY";
export const SET_SAVED_QUERY_STATUS = "SET_SAVED_QUERY_STATUS";
export const SET_BUILDER_MODE = "SET_BUILDER_MODE";
export const DELETE_SAVED_QUERY = "DELETE_SAVED_QUERY";
