import React from "react";
import { useLocation, Link as ReactRouterLink } from "react-router-dom";
import { ThemeToggle } from "../components";
import {
  chakra,
  Flex,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Button,
  useDisclosure,
  Avatar,
  Heading,
  Icon,
  Text,
  Link,
} from "@chakra-ui/react";
import {
  AiOutlineDatabase,
  AiFillCaretDown,
  AiOutlineLogout,
} from "react-icons/ai";
import { useUser, handleLogout } from "../utils";
import { RiFileExcel2Fill } from "react-icons/ri";
import { queryRoles, uploadRoles } from "../constants";

const Header = () => {
  const location = useLocation();
  const user = useUser();
  const mobileNav = useDisclosure();
  const bg = useColorModeValue("white", "gray.800");

  const HeaderNavigation = () => (
    <HStack spacing={3} display={{ base: "inline-flex", md: "inline-flex" }}>
      {queryRoles.includes(user.payload) ? (
        <Link
          as={ReactRouterLink}
          to="/query"
          tabIndex="-1"
          style={{ textDecoration: "none" }}
        >
          <Button
            variant={location.pathname === "/query" ? "solid" : "ghost"}
            borderRadius="0"
            colorScheme="brand"
            leftIcon={<AiOutlineDatabase />}
            size="sm"
          >
            Query
          </Button>
        </Link>
      ) : null}
      {uploadRoles.includes(user.payload) ? (
        <Link
          as={ReactRouterLink}
          to="/upload"
          tabIndex="-1"
          style={{ textDecoration: "none" }}
        >
          <Button
            variant={location.pathname === "/upload" ? "solid" : "ghost"}
            borderRadius="0"
            leftIcon={<RiFileExcel2Fill />}
            colorScheme="brand"
            size="sm"
          >
            Upload
          </Button>
        </Link>
      ) : null}
    </HStack>
  );

  return (
    <chakra.header
      bg={bg}
      w="full"
      pos="sticky"
      zIndex="sticky"
      top="0"
      maxW="120em"
      height="4rem"
      px={{ base: 2, sm: 4 }}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "whiteAlpha.300")}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mx="auto"
        h="full"
      >
        <Heading as="h1" fontSize="2xl" display={{ base: "none", md: "block" }}>
          AS Statistiks 2.0
        </Heading>
        <HeaderNavigation />
        <HStack
          spacing={6}
          display={mobileNav.isOpen ? "none" : "flex"}
          alignItems="center"
        >
          <ThemeToggle />
          <Menu>
            <MenuButton>
              <HStack
                spacing={2}
                display={mobileNav.isOpen ? "none" : "flex"}
                alignItems="center"
              >
                <Avatar
                  name={user.userID}
                  bg={useColorModeValue("brand.500", "brand.200")}
                  color={useColorModeValue("white", "gray.800")}
                  getInitials={(name) => name.slice(0, 2)}
                  fontSize="lg"
                  size="sm"
                />
                <Icon as={AiFillCaretDown} />
              </HStack>
            </MenuButton>
            <MenuList minW="0" maxW="350">
              <MenuItem
                icon={<Icon as={AiOutlineLogout} boxSize={4} />}
                onClick={handleLogout}
              >
                <Text fontSize="md">Logout</Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Flex>
    </chakra.header>
  );
};

export default Header;
