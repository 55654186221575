import { useEffect } from "react";
import { ApiPath } from "../constants";

const LoginPage = () => {
  useEffect(() => {
    window.location.href = ApiPath.login;
  });
  return null;
};

export default LoginPage;
