// import "@daimler/typeface-daimler-cs-web";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { AppProviders } from "./components";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const theme = extendTheme({
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false,
  },
  // fonts: {
  //   heading: "Daimler CS",
  //   body: "Daimler CS",
  // },
  colors: {
    brand: {
      50: "#a6cad8",
      100: "#83b5c9",
      200: "#5fa0b9",
      300: "#79AEBF",
      400: "#5097AB",
      500: "#007A93",
      600: "#00677F",
      700: "#00566A",
      800: "#004355",
      900: "#003340",
    },
  },
  components: {
    Box: (props) => ({
      bg: mode("white", "gray.800")(props),
    }),
  },
});

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
  <ChakraProvider theme={theme}>
    <AppProviders>
      <App />
    </AppProviders>
  </ChakraProvider>
);