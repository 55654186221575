import React from "react";
import QueryBuilder, { defaultValidator } from "react-querybuilder";
import {
  Button,
  Checkbox,
  Input,
  RadioGroup,
  Radio,
  Select,
  Stack,
  VStack,
  useColorModeValue,
  IconButton,
  Text,
} from "@chakra-ui/react";
import "../styles/chakra.scss";
import "react-querybuilder/dist/query-builder.scss";
import { AiOutlinePlus } from "react-icons/ai";
import { RiCloseLine } from "react-icons/ri";

const ChakraNotToggle = ({
  className,
  handleOnChange,
  label,
  checked,
  title,
}) => {
  return (
    <Checkbox
      className={className}
      title={title}
      size="sm"
      onChange={(e) => handleOnChange(e.target.checked)}
      checked={checked}
    >
      {label}
    </Checkbox>
  );
};

const ChakraValueEditor = ({
  operator,
  value,
  handleOnChange,
  title,
  className,
  type,
  inputType,
  values,
  validation,
}) => {
  const color = useColorModeValue("brand.600", "brand.200");

  if (operator === "null" || operator === "notNull") {
    return null;
  }

  switch (type) {
    case "select":
      return (
        <Select
          variant="outline"
          isFullWidth="true"
          size="sm"
          fontSize="md"
          borderRadius="md"
          w="full"
          className={className}
          value={value}
          onChange={(e) => handleOnChange(e.target.value)}
        >
          {values.map((v) => (
            <option key={v.name} value={v.name}>
              {v.label}
            </option>
          ))}
        </Select>
      );

    case "checkbox":
      return (
        <Checkbox
          className={className}
          size="sm"
          onChange={(e) => handleOnChange(e.target.checked)}
          isChecked={!!value}
        />
      );

    case "radio":
      return (
        <RadioGroup
          className={className}
          title={title}
          value={value}
          onChange={handleOnChange}
        >
          <Stack direction="row">
            {values.map((v) => (
              <Radio key={v.name} value={v.name} size="sm">
                {v.label}
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
      );

    default:
      return (
        <VStack align="flex-start" justify="center">
          <Input
            type={inputType || "text"}
            value={value}
            title={title}
            mt={0.5}
            size="xs"
            isFullWidth="true"
            color={color}
            borderColor={color}
            colorScheme="brand"
            width="fit-content"
            variant="outline"
            className={className}
            onChange={(e) => handleOnChange(e.target.value)}
          />
          {!validation.valid && (
            <Text fontSize="xs" color="red.500">
              Required*
            </Text>
          )}
        </VStack>
      );
  }
};

const ChakraGroupActionElement = ({ className, handleOnClick, title }) => (
  <Button
    leftIcon={<AiOutlinePlus />}
    variant="solid"
    colorScheme="brand"
    className={className}
    title={title}
    onClick={(e) => handleOnClick(e)}
    size="xs"
    ml={2}
  >
    Group
  </Button>
);

const ChakraRuleActionElement = ({ className, handleOnClick, title }) => (
  <Button
    leftIcon={<AiOutlinePlus />}
    variant="solid"
    colorScheme="brand"
    className={className}
    title={title}
    onClick={(e) => handleOnClick(e)}
    size="xs"
    ml={2}
  >
    Rule
  </Button>
);

const ChakraValueSelector = ({
  className,
  handleOnChange,
  options,
  value,
  title,
}) => {
  const color = useColorModeValue("brand.600", "brand.200");

  return (
    <Select
      variant="outline"
      isFullWidth="true"
      color={color}
      borderColor={color}
      size="xs"
      colorScheme="brand"
      fontFamily="monospace"
      fontWeight="semibold"
      width="fit-content"
      className={className}
      title={title}
      value={value}
      onChange={(e) => handleOnChange(e.target.value)}
      mr={2}
    >
      {options.map((option) => {
        const key = option.id ? `key-${option.id}` : `key-${option.name}`;
        return (
          <option key={key} value={option.name}>
            {option.label}
          </option>
        );
      })}
    </Select>
  );
};

const ChakraRemoveActionElement = ({ handleOnClick }) => (
  <IconButton
    size="xs"
    fontSize="md"
    ml={2}
    variant="solid"
    color={useColorModeValue("gray.500", "gray.400")}
    colorScheme="gray"
    onClick={(e) => handleOnClick(e)}
    icon={<RiCloseLine />}
  />
);

const controlElements = {
  addGroupAction: ChakraGroupActionElement,
  addRuleAction: ChakraRuleActionElement,
  combinatorSelector: ChakraValueSelector,
  fieldSelector: ChakraValueSelector,
  notToggle: ChakraNotToggle,
  operatorSelector: ChakraValueSelector,
  removeGroupAction: ChakraRemoveActionElement,
  removeRuleAction: ChakraRemoveActionElement,
  valueEditor: ChakraValueEditor,
};

const operators = [
  { name: "=", label: "=" },
  { name: "!=", label: "!=" },
  { name: "contains", label: "contains" },
  { name: "beginsWith", label: "begins with" },
  { name: "endsWith", label: "ends with" },
  { name: "doesNotContain", label: "does not contain" },
  { name: "doesNotBeginWith", label: "does not begin with" },
  { name: "doesNotEndWith", label: "does not end with" },
  { name: "null", label: "is null" },
  { name: "notNull", label: "is not null" },
];

const TableQueryBuilder = ({ query, fields, onQueryChange }) => {
  return (
    <QueryBuilder
      query={query}
      fields={fields}
      onQueryChange={onQueryChange}
      controlElements={controlElements}
      operators={operators}
      showCombinatorsBetweenRules
      addRuleToNewGroups
      validator={defaultValidator}
    />
  );
};

export default TableQueryBuilder;
