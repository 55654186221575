import React from "react";
import {
  Pagination as ChakraPagination,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
  PaginationContainer,
  PaginationPageGroup,
} from "@ajna/pagination";

const Pagination = ({
  pagesCount,
  currentPage,
  setCurrentPage,
  pages,
  isDisabled,
}) => {
  return (
    <ChakraPagination
      pagesCount={pagesCount}
      currentPage={currentPage}
      onPageChange={setCurrentPage}
      isDisabled={isDisabled}
      pb={1}
    >
      <PaginationContainer align="center" justify="center" pb={1}>
        <PaginationPrevious mr={2} size="xs" colorScheme="gray">
          Previous
        </PaginationPrevious>
        <PaginationPageGroup spacing={2} align="center" justify="center">
          {pages.map((page) => (
            <PaginationPage
              key={`pagination_page_${page}`}
              page={page}
              size="xs"
              minWidth={10}
              colorScheme="gray"
              _current={{
                colorScheme: "brand",
              }}
            />
          ))}
        </PaginationPageGroup>
        <PaginationNext ml={2} size="xs" colorScheme="gray">
          Next
        </PaginationNext>
      </PaginationContainer>
    </ChakraPagination>
  );
};

export default Pagination;
