import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Box,
  Td,
  useColorModeValue,
  Skeleton,
  Icon,
  Text,
  Center,
  chakra,
  VStack,
} from "@chakra-ui/react";
import { BiLinkExternal } from "react-icons/bi";

const RupfZblTable = ({
  data,
  handleXmlCode,
  isLoading,
  onImportModalOpen,
}) => {
  const rupfZblTableHeaders = [
    { name: "" },
    { name: "ID" },
    { name: "TS" },
    { name: "UC" },
    { name: "FIN" },
    { name: "REASONCODE" },
    { name: "XML" },
    { name: "CompareDate" },
    { name: "Diogenes" },
  ];

  const linkColor = useColorModeValue("brand.500", "brand.200");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.300");
  const bg = useColorModeValue("gray.50", "gray.700");
  const textColor = useColorModeValue("gray.600", "gray.400");
  const tableBodyColor = useColorModeValue("white", "gray.800");

  const ImportButton = () => {
    return (
      <chakra.label>
        <Text
          as="label"
          color={useColorModeValue("brand.500", "brand.200")}
          fontSize="xl"
          decoration="underline"
          sx={{ cursor: "pointer" }}
          onClick={onImportModalOpen}
        >
          Import
        </Text>
      </chakra.label>
    );
  };

  return (
    <Box
      w="full"
      h={{ base: "calc(100vh - 10rem)", md: "calc(100vh - 9rem)" }}
      maxH={{ base: "calc(100vh - 9rem)", md: "calc(100vh - 9rem)" }}
      overflow="hidden"
      borderWidth="1px"
      borderColor={borderColor}
      bg={bg}
    >
      <Skeleton
        isLoaded={!isLoading}
        height={{ base: "calc(100vh - 9rem)", md: "calc(100vh - 9rem)" }}
        overflow="auto"
      >
        {!(Array.isArray(data) && data.length) ? (
          <Center h="full" w="full">
            <VStack>
              <Text as="code" fontSize="lg">
                No data to display
              </Text>
              <Text as="code" fontSize="lg">
                <ImportButton /> an Excel file
              </Text>
            </VStack>
          </Center>
        ) : (
          <Table size="sm" variant="unstyled">
            <Thead
              pos="sticky"
              top="0"
              zIndex="1111"
              borderBottomWidth="1px"
              borderColor={borderColor}
            >
              <Tr>
                {rupfZblTableHeaders.map((header) => (
                  <Th
                    key={header.name}
                    bg={bg}
                    borderRightWidth="1px"
                    borderBottomWidth="1px"
                    borderColor={borderColor}
                  >
                    {header.name}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody bg={tableBodyColor}>
              {data.map((row, i) => (
                <Tr
                  key={row.id}
                  borderBottomWidth="1px"
                  borderColor={borderColor}
                >
                  <Td
                    borderRightWidth="1px"
                    borderColor={borderColor}
                    bg={bg}
                    fontWeight="bold"
                    color={textColor}
                    pos="sticky"
                    left="0"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    {i + 1}
                  </Td>
                  <Td
                    borderRightWidth="1px"
                    sx={{ whiteSpace: "nowrap" }}
                    borderColor={borderColor}
                  >
                    {row.id}
                  </Td>
                  <Td
                    borderRightWidth="1px"
                    sx={{ whiteSpace: "nowrap" }}
                    borderColor={borderColor}
                  >
                    {row.ts}
                  </Td>
                  <Td
                    borderRightWidth="1px"
                    sx={{ whiteSpace: "nowrap" }}
                    borderColor={borderColor}
                  >
                    {row.uc}
                  </Td>
                  <Td
                    borderRightWidth="1px"
                    sx={{ whiteSpace: "nowrap" }}
                    borderColor={borderColor}
                  >
                    {row.fin}
                  </Td>
                  <Td
                    borderRightWidth="1px"
                    sx={{ whiteSpace: "nowrap" }}
                    borderColor={borderColor}
                  >
                    {row.reasoncode}
                  </Td>
                  <Td
                    color={linkColor}
                    onClick={() => handleXmlCode(row.id)}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      whiteSpace: "nowrap",
                    }}
                    borderRightWidth="1px"
                    borderColor={borderColor}
                  >
                    XML <Icon as={BiLinkExternal} />
                  </Td>
                  <Td
                    sx={{ whiteSpace: "nowrap" }}
                    borderRightWidth="1px"
                    borderColor={borderColor}
                  >
                    {row.comparedate}
                  </Td>
                  <Td
                    sx={{ whiteSpace: "nowrap" }}
                    borderRightWidth="1px"
                    borderColor={borderColor}
                  >
                    {row.diogenes}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Skeleton>
    </Box>
  );
};

export default RupfZblTable;
