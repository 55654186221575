import React, { useState, useEffect, useContext, createContext } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { LoginPage } from "../pages";
import { FullPageSpinner } from "../components";
import { ApiPath, allRoles } from "../constants";

export const handleLogout = async () => {
  try {
    const response = await fetch(ApiPath.logout, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    await response.json();
    window.location.href = ApiPath.logoutRedirect;
  } catch (err) {
    console.error(err);
  }
};

const AuthContext = createContext();
const UserContext = createContext();

function AuthProvider(props) {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(ApiPath.userInfo, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        });
        const data = await response.json();
        setUser(data);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
        setUser(false);
        setIsLoading(false);
      }
    };
    fetchUser();
  }, []);

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return <AuthContext.Provider value={{ user }} {...props} />;
}

const useAuth = () => useContext(AuthContext);

const UserProvider = (props) => (
  <UserContext.Provider value={useAuth().user} {...props} />
);

const useUser = () => useContext(UserContext);

export { AuthProvider, useAuth, UserProvider, useUser };

export const ProtectedRoute = ({
  component: Component,
  user,
  validRoles,
  ...rest
}) => {
  let navigate = useNavigate();

  return (
    <Routes>
      <Route
        {...rest}
        render={(props) => {
          if (!user.isActive) {
            return <LoginPage />;
          } else if (validRoles.includes(user.payload)) {
            console.log("login got bypass", user.isActive);
            return <Component {...rest} {...props} />;
          } else if (allRoles.includes(user.payload)) {
            alert("Sorry! You do not have access to this functionality.");
            navigate("/");

          } else {
            alert("Sorry! You do not have access to the application.");
            handleLogout();
          }
        }}
      />
    </Routes>
  );
};
