import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";

const ModalDialog = ({
  isModalOpen,
  onModalClose,
  modalProps = null,
  modalTitle,
  modalBody,
  modalBodyProps = null,
  modalPrimaryActionText,
  modalPrimaryActionProps = null,
  hasFooter = true,
}) => {
  return (
    <Modal
      onClose={onModalClose}
      isOpen={isModalOpen}
      isCentered
      returnFocusOnClose={false}
      {...modalProps}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="md">{modalTitle}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6} {...modalBodyProps}>
          {modalBody}
        </ModalBody>
        {hasFooter && (
          <ModalFooter>
            <Button onClick={onModalClose} mr={3} size="sm">
              Cancel
            </Button>
            <Button colorScheme="brand" {...modalPrimaryActionProps} size="sm">
              {modalPrimaryActionText}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ModalDialog;
