import React from "react";
import { Header } from "../components";
import { Box, Container, useColorModeValue, Center } from "@chakra-ui/react";

const MainLayout = ({ children }) => {
  return (
    <Box
      d="flex"
      flexDirection="column"
      minH="100vh"
      bg={useColorModeValue("white", "gray.800")}
    >
      <Center>
        <Header />
      </Center>
      <Container maxW="120em" h="calc(100vh - 4rem)">
        {children}
      </Container>
    </Box>
  );
};

export default MainLayout;
