import React from "react";
import { Tag } from "@chakra-ui/react";

const CustomTag = ({ text, ...style }) => (
  <Tag fontFamily="monospace" colorScheme="brand" fontWeight="bold" {...style}>
    {text}
  </Tag>
);

export default CustomTag;
