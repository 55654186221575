import React, { useEffect, useReducer, useCallback } from "react";
import { MainLayout, SidebarLayout } from "../layouts";
import { RupfZblTable, ModalDialog, UploadArea } from "../components";
import {
  ApiPath,
  FETCH_INIT,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  PARSE_INIT,
  PARSE_SUCCESS,
  PARSE_FAILURE,
  UPLOAD_INIT,
  UPLOAD_FINISH,
  UPLOAD_SUCCESSFUL,
  SET_XML,
  DELETE_INIT,
  DELETE_TABLE,
  DELETE_FAILURE,
  DELETE_SUCCESSFUL,
  ERROR,
} from "../constants";
import beautify from "xml-beautifier";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { xml } from "react-syntax-highlighter/dist/esm/languages/hljs";
import {
  tomorrowNight,
  vs,
} from "react-syntax-highlighter/dist/esm/styles/hljs";

import { deflate } from "pako";
import {
  IconButton,
  Text,
  useColorModeValue,
  useDisclosure,
  Stack,
  VStack,
  Tag,
  Button,
  useToast,
  HStack,
  Tooltip,
} from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";

SyntaxHighlighter.registerLanguage("xml", xml);

const schemaName = process.env.REACT_APP_SCHEMA;

const UploadPage = () => {
  const mobileSideNav = useDisclosure();

  const {
    isOpen: isImportModalOpen,
    onOpen: onImportModalOpen,
    onClose: onImportModalClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();
  const {
    isOpen: isXMLModalOpen,
    onOpen: onXMLModalOpen,
    onClose: onXMLModalClose,
  } = useDisclosure();

  const toast = useToast({
    duration: 4000,
    isClosable: true,
    position: "bottom-right",
    variant: "solid",
  });

  const initialState = {
    tableData: [],
    parsedData: { data: null, filename: "" },
    xml: { id: null, code: "" },
    isLoading: false,
    isParsing: false,
    isUploading: false,
    isDeleting: false,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case FETCH_INIT:
        return {
          ...state,
          isLoading: true,
        };
      case FETCH_SUCCESS:
        return {
          ...state,
          tableData: action.payload,
          isLoading: false,
        };
      case FETCH_FAILURE:
        return {
          ...state,
          isLoading: false,
        };
      case PARSE_INIT:
        return {
          ...state,
          isParsing: true,
        };
      case PARSE_SUCCESS:
        return {
          ...state,
          parsedData: action.payload,
          isParsing: false,
        };
      case PARSE_FAILURE:
        return {
          ...state,
          isParsing: false,
        };
      case UPLOAD_INIT:
        return {
          ...state,
          isUploading: true,
        };
      case UPLOAD_FINISH:
        return {
          ...state,
          isUploading: false,
          parsedData: { data: null, filename: "" },
        };
      case SET_XML:
        return {
          ...state,
          xml: action.payload,
        };
      case DELETE_INIT:
        return {
          ...state,
          isDeleting: true,
        };
      case DELETE_TABLE:
        return {
          ...state,
          tableData: [],
          parsedData: { data: null, filename: "" },
          isDeleting: false,
        };
      case DELETE_FAILURE:
        return {
          ...state,
          isDeleting: false,
        };
      default:
        throw new Error("Missing dispatch action");
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchData = useCallback(async () => {
    let didCancel = false;

    dispatch({ type: FETCH_INIT });
    try {
      const response = await fetch(ApiPath.read, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok) {
        throw new Error(response.status);
      }
      const result = await response.json();
      const url = result['message'] ?? null;
      if (!url) throw new Error('Error reading data');
      const presignedurl_response = await fetch(url, {
        method: "GET"
      });
      if (!presignedurl_response.ok) {
        throw new Error(response.status);
      }
      console.log('presignedurl_response: ', presignedurl_response)
      const presignedurl_result = await presignedurl_response.json();
      console.log('presignedurl_result: ', presignedurl_result);
      if (!didCancel) {
        dispatch({ type: FETCH_SUCCESS, payload: presignedurl_result });
      }
    } catch (err) {
      console.error(err);
      if (!didCancel) {
        dispatch({ type: FETCH_FAILURE });
        toast({
          description: ERROR,
          status: "error",
        });
      }
    }

    return () => {
      didCancel = true;
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleXmlCode = (selectedID) => {
    const selectedRow = state.tableData.filter(
      (row) => row.id === selectedID
    )[0];

    const code = beautify(selectedRow.xml);
    dispatch({ type: SET_XML, payload: { id: selectedID, code } });
    onXMLModalOpen();
  };

  const uploadParsedData = async () => {
    if (state.parsedData.data) {
      dispatch({ type: UPLOAD_INIT });
      try {
        const data = JSON.stringify(state.parsedData.data);
        const compressedData = deflate(data, { level: 9 });
        const response = await fetch(ApiPath.upload, {
          method: "Post",
          headers: {
            "Content-Encoding": "gzip",
          },
          body: compressedData,
        });
        await response.json();
        await fetchData();
        dispatch({ type: UPLOAD_FINISH });
        onImportModalClose();
        toast({ description: UPLOAD_SUCCESSFUL, status: "success" });
      } catch (error) {
        console.error(error);
        dispatch({ type: UPLOAD_FINISH });
        toast({
          description: ERROR,
          status: "error",
        });
      }
    }
  };

  const RenderMain = () => {
    const deleteIconColor = useColorModeValue("gray.500", "gray.400");
    return (
      <VStack spacing={0} w="full" minH="full">
        <HStack spacing={3} justify="space-between" w="full" h="3rem" pb={2}>
          <Text fontSize="lg" fontWeight="semibold">
            Table Content
          </Text>
          <Tooltip
            shouldWrapChildren
            hasArrow
            label="Delete table content"
            placement="left"
          >
            <IconButton
              size="sm"
              fontSize="md"
              aria-label="Delete table content"
              variant="ghost"
              onClick={onDeleteModalOpen}
              color={deleteIconColor}
              colorScheme="gray"
              icon={<FaTrash />}
              disabled={
                !(Array.isArray(state.tableData) && state.tableData.length)
              }
            />
          </Tooltip>
        </HStack>
        <RupfZblTable
          data={state.tableData}
          isLoading={state.isLoading}
          handleXmlCode={handleXmlCode}
          onImportModalOpen={onImportModalOpen}
        />
      </VStack>
    );
  };

  const handleDelete = async () => {
    dispatch({ type: DELETE_INIT });
    try {
      await fetch(ApiPath.delete, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      dispatch({ type: DELETE_TABLE });
      onDeleteModalClose();
      toast({
        description: DELETE_SUCCESSFUL,
        status: "success",
      });
    } catch (err) {
      console.error(err);
      dispatch({ type: DELETE_FAILURE });
      toast({
        description: ERROR,
        status: "error",
      });
    }
  };

  const openImportModal = () => {
    mobileSideNav.onClose();
    onImportModalOpen();
  };

  const RenderSideContent = () => (
    <Stack direction="column" spacing={8} w="full" align="flex-start" py="6">
      <VStack spacing={1} align="flex-start">
        <Text fontSize="sm" fontWeight="semibold">
          SCHEMA
        </Text>
        <Tag
          fontFamily="monospace"
          colorScheme="brand"
          fontSize="sm"
          fontWeight="semibold"
        >
          {schemaName}
        </Tag>
      </VStack>
      <VStack spacing={1} align="flex-start">
        <Text fontSize="sm" fontWeight="semibold">
          TABLE
        </Text>
        <Tag
          fontFamily="monospace"
          colorScheme="brand"
          fontSize="sm"
          fontWeight="semibold"
        >
          rupf_zbl_table
        </Tag>
      </VStack>
      <VStack spacing={1} align="flex-start">
        <Text fontSize="sm" fontWeight="semibold">
          DATA SOURCE
        </Text>
        <Button
          colorScheme="brand"
          variant="outline"
          size="sm"
          w="full"
          onClick={openImportModal}
        >
          Import
        </Button>
      </VStack>
    </Stack>
  );

  return (
    <MainLayout>
      <SidebarLayout
        mobileSideNav={mobileSideNav}
        sidebarContent={<RenderSideContent />}
        mainContent={<RenderMain />}
      />
      {/*  Delete rupf_zbl_table content modal */}
      <ModalDialog
        isModalOpen={isDeleteModalOpen}
        onModalClose={onDeleteModalClose}
        modalTitle="Delete table content?"
        modalBody={
          <>
            Are you sure you want to delete the contents of{" "}
            <code>rupf_zbl_table</code>?
          </>
        }
        modalBodyProps={{ fontSize: "lg" }}
        modalPrimaryActionText="Delete"
        modalPrimaryActionProps={{
          onClick: handleDelete,
          isLoading: state.isDeleting,
          loadingText: "Deleting",
          spinnerPlacement: "end",
        }}
      />
      {/* Import Excel file modal */}
      <ModalDialog
        isModalOpen={isImportModalOpen}
        onModalClose={onImportModalClose}
        modalTitle="Import an Excel file"
        modalBody={
          <UploadArea
            parsedData={state.parsedData}
            isParsing={state.isParsing}
            isUploading={state.isUploading}
            dispatch={dispatch}
          />
        }
        modalPrimaryActionText="Upload"
        modalPrimaryActionProps={{
          onClick: uploadParsedData,
          isLoading: state.isUploading,
          loadingText: "Uploading",
          disabled: !state.parsedData.data || state.isParsing,
          spinnerPlacement: "end",
        }}
      />
      {/* XML viewer modal */}
      <ModalDialog
        isModalOpen={isXMLModalOpen}
        onModalClose={onXMLModalClose}
        modalProps={{ scrollBehavior: "inside", size: "3xl" }}
        modalTitle={<code>ID - {state.xml.id}</code>}
        modalBody={
          <SyntaxHighlighter
            className="syntax-highlighter"
            language="xml"
            style={useColorModeValue(vs, tomorrowNight)}
            wrapLongLines
          >
            {state.xml.code}
          </SyntaxHighlighter>
        }
        modalBodyProps={{ pt: 0 }}
        hasFooter={false}
      />
    </MainLayout>
  );
};

export default UploadPage;
